.Card-Container{
    background-color: #E3F2FD;
    width: 100%;
    border-radius: 15px;
}
.Card-Container-Cargar-Data{
    background-color: #E3F2FD;
    width: 100%;
    /* height: 100%; */
    border-radius: 15px;
}
.ant-pagination-options{
    display: none !important;
}
.Card-Container .ant-card-body{
    border-radius: 15px;
    border: 0;
    padding: 12px;
    padding-bottom: 0;
    height: 120%;
    overflow-y: hidden!important;
    overflow-x: scroll;
}
.Card-Container .ant-card-head{
    min-height: 40px;
    padding: 0 20px;
}

.Card-Container-CargarData .ant-card-body{
    width: 100%;
    padding: 18px;
}
.Card-Container-CargarData .ant-card-body{
    height: auto;
}
.Card-Item{
    height: 100%;
    border: 0;
    border-radius: 15px 15px 0 0;
}
.Card-Item .ant-card-body{
    border-radius: 10px;
    border: 0;
    padding: 15px 20px;
    height: calc(100% - 90px);
    overflow-y: auto;
}
.Card-Item .ant-card-body::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.Card-Item .ant-card-body::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 0;
    border-radius: 50px;
}
.Card-Item .ant-card-body::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 50px 20px;
}
.Logo-Container{
    width: 160px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.Icon-Add-Homologados{
    font-size: 18px;
    color: rgb(26, 191, 26);
}
.Icon-Edit-Combo-Homologados{
    font-size: 18px;
    color: rgb(26, 191, 26);
    cursor: pointer;
}
.Icon-Edit-Not-Homologaciones, .Icon-Edit-Homologados{
    font-size: 18px;
    color: rgb(54, 168, 213);
}
.Icon-Edit-Not-Homologaciones{
    margin-right: 10px;
}
.Table-Home .ant-pagination{
    margin-bottom: 0 !important;
}
.Table-Home .ant-table{
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    margin-top: 12px;
}
.Table-Home .ant-table .ant-checkbox-inner{
    height: 13px;
    width: 13px;
}

.Table-Home .ant-table-thead .ant-table-cell-scrollbar{
    background-color: #E3F2FD !important;
}
.Table-Home.Asignar-hml .ant-table-thead .ant-table-cell-scrollbar{
    background-color: #013A81 !important;
}
.Table-Home .ant-table-thead tr th.Tabla-Actions-Home{
    background-color: rgb(1, 132, 255) !important;
    color: #ffffff;
}
.Table-Home .ant-table-thead th{
    font-size: 10px;
    padding: 2px 5px !important;
    font-family: 'Inter';
    background-color: #E3F2FD !important;
}

/* CABECERA SO */
.Table-Home .ant-table-thead th:has(.Cabecera-SO-Indicares-Table-HML), .Table-Home .ant-table-thead th:has(.Cabecera-SO-Indicares-Table-HM.Cabecera-Blue){
    background-color: #3ED5C6 !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-SO-Indicares-Table-HML), .Table-Home .ant-table-thead th:hover:has(.Cabecera-SO-Indicares-Table-HM.Cabecera-Blue){
    background-color: #3ED5C6 !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:has(.Cabecera-SO-Indicares-Table-HML.Cabecera-Blue){
    background-color: #013A81 !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-SO-Indicares-Table-HML.Cabecera-Blue){
    background-color: #013A81 !important;
}
/* FIN CABECERA SO */


/* CABECERA INVENTARIOS */
.Table-Home .ant-table-thead th:has(.Cabecera-INV-Indicares-Table-HML){
    background-color: #66CD8A !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-INV-Indicares-Table-HML){
    background-color: #66CD8A !important;
    color: white !important;
    font-weight: bold !important;
}
/* FIN CABECERA INVENTARIOS */

/* CABECERA MAESTRA PRODUCTOS */
.Table-Home .ant-table-thead th:has(.Cabecera-MPROD-Indicares-Table-HML){
    background-color: #013A81 !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-MPROD-Indicares-Table-HML){
    background-color: #013A81 !important;
    color: white !important;
    font-weight: bold !important;
}
/* FIN CABECERA MAESTRA PRODUCTOS */

/* CABECERA MAESTRA PRECIOS */
.Table-Home .ant-table-thead th:has(.Cabecera-MPRECIOS-Indicares-Table-HML){
    background-color: #FFC04C !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-MPRECIOS-Indicares-Table-HML){
    background-color: #FFC04C !important;
    color: white !important;
    font-weight: bold !important;
}
/* FIN CABECERA MAESTRA PRECIOS */


/* CABECERA SELL IN  */
.Table-Home .ant-table-thead th:has(.Cabecera-SI-Indicares-Table-HML){
    background-color: #FF3333 !important;
    color: white !important;
    font-weight: bold !important;
}
.Table-Home .ant-table-thead th:hover:has(.Cabecera-SI-Indicares-Table-HML){
    background-color: #FF3333 !important;
    color: white !important;
    font-weight: bold !important;
}
/* FIN CABECERA SELL IN  */

.ant-table-row-expand-icon-collapsed{
    display: none !important;
}

.Table-Home .ant-table-thead th:hover{
    background-color: #f7f7ed !important;
}
.Table-Home .ant-table-thead tr th span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.Table-Home .ant-table-body .ant-table-tbody td.Tabla-Actions-Home{
    display: flex;
    align-items: center;
    padding: 4px !important;
    column-gap: 9px;
}
.Table-Home .ant-table-body::-webkit-scrollbar{
    width: 7px;
    height: 7px;
}
.Table-Home .ant-table-body::-webkit-scrollbar-thumb{
    background-color: #013A81;
    border: 0;
    border-radius: 50px;
}
.Table-Home .ant-table-body::-webkit-scrollbar-track{
    background-color: transparent;
    border-radius: 10px;
    margin: 10px 20px;
}
.Table-Home .ant-table-tbody tr:hover td{
    background-color: #f6fbff !important;
}
.Table-Home .ant-table-tbody tr.ant-table-measure-row td{
    padding: 0 !important;
}
.Table-Home .ant-table-tbody tr td{
    font-size: 10px;
    padding: 3px 9px !important;
    line-height: 1;
}
.Table-Home.Table-Small .ant-table-tbody tr td{
    padding: 0 9px !important;
}
.Table-Home .ant-table-tbody tr td div{
    font-family: 'Inter';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.Table-Home .ant-pagination{
    margin-top: 0 !important;
    font-family: 'Inter';
    font-size: 12px;
}

.Table-Home .ant-pagination-item{
    margin: 0 5px !important;
}
.Tag-Combo{
    background-color: #D7FCD2;
    border-radius: 8px;
    border: 1px solid #5aff5a;
    text-align: center;
    width: 80px;
    margin: 0 auto;
}
.Tag-Not-Combo{
    background-color: #FFD9D7;
    border-radius: 8px;
    border: 1px solid #f18781;
    text-align: center;
    width: 80px;
    margin: 0 auto;
}
.Button-Title-Module{
    margin-right: 10px;
    font-size: 12px !important;
    height: 20px !important;
}
.Title-Module{
    font-size: 15px !important;
    margin: 0 !important;
}